import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Search } = Input;

function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');

    useEffect(() => {
        fetchTop100Tokens();
    }, []);

    const fetchTop100Tokens = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/api/tokens/top100');
            setTokens(response.data);
        } catch (error) {
            console.error('获取Token列表失败:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Token',
            dataIndex: 'symbol',
            key: 'symbol',
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <a href={`/token/${text}`}>
                    {`${text.slice(0, 6)}...${text.slice(-4)}`}
                </a>
            ),
        },
        {
            title: '市值 (USD)',
            dataIndex: 'marketCap',
            key: 'marketCap',
            render: (value) => `$${Number(value).toLocaleString()}`,
            sorter: (a, b) => a.marketCap - b.marketCap,
        },
        {
            title: '当前价格',
            dataIndex: 'currentPrice',
            key: 'currentPrice',
            render: (value) => `$${Number(value).toFixed(8)}`,
            sorter: (a, b) => a.currentPrice - b.currentPrice,
        },
    ];

    const handleSearch = (value) => {
        setSearchAddress(value);
        // 实现搜索逻辑
    };

    return (
        <div className="dashboard">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title="Token 搜索">
                        <Search
                            placeholder="输入Token地址"
                            enterButton={<Button type="primary" icon={<SearchOutlined />}>搜索</Button>}
                            size="large"
                            onSearch={handleSearch}
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title="Top 100 Tokens">
                        <Table
                            columns={columns}
                            dataSource={tokens}
                            rowKey="address"
                            loading={loading}
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
