import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, LineChartOutlined } from '@ant-design/icons';

const { Header } = Layout;

function AppHeader() {
  const location = useLocation();

  const menuItems = [
    {
      key: '/',
      icon: <HomeOutlined />,
      label: <Link to="/">首页</Link>,
    },
    {
      key: '/analysis',
      icon: <LineChartOutlined />,
      label: <Link to="/analysis">分析</Link>,
    },
  ];

  return (
    <Header className="app-header">
      <div className="app-logo">
        Solana Smart Money Bot
      </div>
      <Menu
        className="app-menu"
        mode="horizontal"
        selectedKeys={[location.pathname]}
        items={menuItems}
      />
    </Header>
  );
}

export default AppHeader;
