
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from './components/AppHeader';
import Dashboard from './pages/Dashboard';
import TokenDetail from './pages/TokenDetail';
import NotFound from './pages/NotFound';
import './App.css';

const { Content } = Layout;

function App() {
  return (
    <Router>
      <Layout className="app-layout">
        <AppHeader />
        <Content className="app-content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/token/:address" element={<TokenDetail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
