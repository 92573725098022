import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Table, Statistic, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

function TokenDetail() {
    const { address } = useParams();
    const [loading, setLoading] = useState(true);
    const [tokenData, setTokenData] = useState(null);
    const [priceData, setPriceData] = useState([]);
    const [holders, setHolders] = useState([]);

    useEffect(() => {
        if (address) {
            fetchTokenData();
            fetchPriceData();
            fetchHolders();
        }
    }, [address]);

    const fetchTokenData = async () => {
        try {
            const response = await axios.get(`/api/tokens/${address}`);
            setTokenData(response.data);
        } catch (error) {
            console.error('获取Token信息失败:', error);
        }
    };

    const fetchPriceData = async () => {
        try {
            const response = await axios.get(`/api/transactions/${address}/prices`);
            setPriceData(response.data);
        } catch (error) {
            console.error('获取价格数据失败:', error);
        }
    };

    const fetchHolders = async () => {
        try {
            const response = await axios.get(`/api/transactions/${address}/holders`);
            setHolders(response.data);
        } catch (error) {
            console.error('获取持有者数据失败:', error);
        } finally {
            setLoading(false);
        }
    };

    const priceChartOption = {
        title: {
            text: '价格走势'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'time',
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        series: [{
            name: '价格',
            type: 'line',
            data: priceData,
            smooth: true
        }]
    };

    const holderColumns = [
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <a href={`https://solscan.io/account/${text}`} target="_blank" rel="noopener noreferrer">
                    {`${text.slice(0, 6)}...${text.slice(-4)}`}
                </a>
            ),
        },
        {
            title: '持有数量',
            dataIndex: 'balance',
            key: 'balance',
            sorter: (a, b) => a.balance - b.balance,
        },
        {
            title: '获利倍数',
            dataIndex: 'profitRatio',
            key: 'profitRatio',
            render: (value) => `${value}x`,
            sorter: (a, b) => a.profitRatio - b.profitRatio,
        },
    ];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className="token-detail">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Statistic title="当前价格" value={tokenData?.currentPrice} prefix="$" />
                            </Col>
                            <Col span={6}>
                                <Statistic title="市值" value={tokenData?.marketCap} prefix="$" />
                            </Col>
                            <Col span={6}>
                                <Statistic title="总供应量" value={tokenData?.totalSupply} />
                            </Col>
                            <Col span={6}>
                                <Statistic title="持有者数量" value={holders.length} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <ReactECharts option={priceChartOption} style={{ height: '400px' }} />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title="智能资金持有者">
                        <Table
                            columns={holderColumns}
                            dataSource={holders}
                            rowKey="address"
                            pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default TokenDetail;
